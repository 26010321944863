/* eslint-disable @typescript-eslint/no-explicit-any */

const playPublishSubscribeScript = (() => {
  const lastEvent: { [topicName: string]: any } = {};
  const subscribers: { [topicName: string]: ((data: any) => void)[] } = {};

  window.__PubSub_PLAY__ = {
    publish: <Topic extends string, Result>(topic: Topic, data: Result) => {
      lastEvent[topic] = data;
      if (!Array.isArray(subscribers[topic])) {
        return;
      }
      subscribers[topic].forEach((callback) => {
        callback(data);
      });
    },
    subscribe: <Topic extends string, Result>(topic: Topic, callback: (data: Result) => void) => {
      if (subscribers[topic] === undefined) {
        subscribers[topic] = [];
      }
      subscribers[topic].push(callback);
      if (lastEvent[topic]) {
        callback(lastEvent[topic]);
        lastEvent[topic] = undefined;
      }
      return function unsubscribe() {
        subscribers[topic] = subscribers[topic].filter((subscriberFoo) => subscriberFoo !== callback);
      };
    },
  };
}).toString();

export const PlayPublishSubscribeScript = ({ nonce }: { nonce: string }) => (
  <script
    nonce={nonce}
    suppressHydrationWarning
    dangerouslySetInnerHTML={{
      __html: `(${playPublishSubscribeScript})()`,
    }}
  />
);
