const browserSupportCheck = (() => {
  const checkFlexGap = () => {
    // create flex container with row-gap set
    if (!document) return false;
    const flex = document.createElement('div');
    flex.style.display = 'flex';
    flex.style.flexDirection = 'column';
    flex.style.rowGap = '1px';

    // create two, elements inside it
    flex.appendChild(document.createElement('div'));
    flex.appendChild(document.createElement('div'));

    // append to the DOM (needed to obtain scrollHeight)
    if (!document.body) return false;

    document.body.appendChild(flex);
    const isSupported = flex.scrollHeight === 1; // flex container should be 1px high from the row-gap

    if (!flex.parentNode) return false;

    flex.parentNode.removeChild(flex);
    return isSupported;
  };

  new Promise<boolean>((resolve) => {
    document.addEventListener('DOMContentLoaded', () => {
      resolve(checkFlexGap());
    });
  }).then((flexCheck) => {
    const LEGACY_PATH_EXTENSION = '/legacy-browser';
    const LEGACY_BROWSER_ROUTE = `/play${LEGACY_PATH_EXTENSION}`;
    const browserIsSupported = (flexCheck && CSS.supports('padding-top: min(1712px, 80vh)')) || window.location.pathname.includes('/embed');

    if (!browserIsSupported) {
      const { pathname } = window.location;
      const pathParts = pathname.split('/').filter((path) => !!path);

      if (pathname.indexOf(LEGACY_BROWSER_ROUTE) < 0) {
        const bu = pathParts[0] === 'play' ? '' : `/${pathParts[0]}`;
        const url = bu + LEGACY_BROWSER_ROUTE;

        window.location.replace(url);
      }
    }
  });
}).toString();

export const BrowserSupportCheckScript = ({ nonce }: { nonce: string }) => (
  <script
    nonce={nonce}
    suppressHydrationWarning
    dangerouslySetInnerHTML={{
      __html: `(${browserSupportCheck})()`,
    }}
  />
);
