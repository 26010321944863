import { Suspense } from 'react';

import { ClientHintCheck, getHints } from '@app/providers/client-hints.provider';
import { useNonce } from '@app/providers/nonce.provider';
import isPropValid from '@emotion/is-prop-valid';
import { defaultTheme } from '@play-now/core/styles/Themes';
import { LinksFunction, LoaderFunctionArgs, json } from '@remix-run/node';
import { Links, Meta, Outlet, Scripts, ScrollRestoration, isRouteErrorResponse, useLoaderData, useRouteError } from '@remix-run/react';
import { StyleSheetManager, ThemeProvider } from 'styled-components';

import { href as iconsHref } from './components/Icon/Icon';
import fontStyleSheetUrl from './styles/SRGSSRType.css?url';
import { BrowserSupportCheckScript } from './utils/html-inlines/scripts/BrowserSupportCheckScript';
import { CmpEventScript } from './utils/html-inlines/scripts/CmpEventScript';
import { PlayPublishSubscribeScript } from './utils/html-inlines/scripts/PlayPublishSubscribeScript';
import { ExternalScripts } from './utils/remix/external-scripts/external-scripts';
import { isRequestedViaCDN } from './utils/server/controller.server';
import { getTheme } from './utils/server/theme.server';

export const links: LinksFunction = () => [
  { rel: 'preload', href: iconsHref, as: 'image' },
  { rel: 'preload', href: fontStyleSheetUrl, as: 'style' },
  { rel: 'stylesheet', href: fontStyleSheetUrl },
];

export const loader = ({ request }: LoaderFunctionArgs) =>
  json({
    requestInfo: {
      hints: getHints(request),
      userPrefs: { theme: getTheme(request) },
      isRequestedViaCDN: isRequestedViaCDN(request),
    },
  });

export const shouldRevalidate = () => false;

// This top level error boundary is only reached for errors that occur in API routes.
// All errors thrown within the app, will be caught further down the tree by the error
// boundary in ErrorBoundary.remix-route.tsx
export const ErrorBoundary = () => {
  const error = useRouteError();
  const errorMessage = isRouteErrorResponse(error)
    ? `${error.status} ${error.statusText}`
    : error instanceof Error
      ? error.message
      : 'Unknown Error';

  return (
    <html>
      <head>
        <title>{errorMessage}</title>
      </head>
      <body>
        <h1>{errorMessage}</h1>
      </body>
    </html>
  );
};

const App = () => {
  const {
    requestInfo: { isRequestedViaCDN: isViaCDN },
  } = useLoaderData<typeof loader>();
  const nonce = useNonce();

  return (
    <html>
      <head>
        <meta charSet='utf-8' />
        <Meta />
        <Links />
        <PlayPublishSubscribeScript nonce={nonce} />
        <CmpEventScript nonce={nonce} />
        <script
          nonce={nonce}
          suppressHydrationWarning
          dangerouslySetInnerHTML={{
            __html: `
              window.playVersion = ${JSON.stringify({
                commitSha: import.meta.env.VITE_COMMIT_SHA,
                timestamp: import.meta.env.VITE_TIMESTAMP,
                letterboxVersion: import.meta.env.VITE_LBW_VERSION,
              })};
              window.__PLAY__ = ${JSON.stringify({ isRequestedViaCDN: isViaCDN })};`,
          }}
        />
        <ClientHintCheck nonce={nonce} />
        <ExternalScripts />
        {/* __STYLES__ and __PLAY_DEBUG__ will be replaced in entry-server.tsx */}
        {typeof document === 'undefined' ? '__STYLES__' : null}
        {typeof document === 'undefined' ? '__PLAY_DEBUG__' : null}
      </head>
      <body>
        <BrowserSupportCheckScript nonce={nonce} />
        <Suspense>
          <StyleSheetManager shouldForwardProp={isPropValid} enableVendorPrefixes={true}>
            <ThemeProvider theme={defaultTheme}>
              <Outlet />
            </ThemeProvider>
          </StyleSheetManager>
        </Suspense>
        <ScrollRestoration nonce={nonce} />
        <Scripts nonce={nonce} />
      </body>
    </html>
  );
};

export default App;
