import type { UcDetail } from '@play-now/video/models/UcDetail';

const cmpEventScript = (() => {
  const onUcEvent = (e: CustomEvent<UcDetail>) => {
    window.__PubSub_PLAY__.publish('ucEvent', e.detail);
  };

  window.addEventListener('ucEvent', onUcEvent);
}).toString();

export const CmpEventScript = ({ nonce }: { nonce: string }) => (
  <script
    nonce={nonce}
    suppressHydrationWarning
    dangerouslySetInnerHTML={{
      __html: `(${cmpEventScript})()`,
    }}
  />
);
